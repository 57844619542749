//
//
// type.scss
//
//


body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// Responsive text sizes
//
// Adjusted base font size for smaller screen sizes

@include media-breakpoint-down(md) {
  html {
    font-size: 0.875rem;
  }
}

// Heading tags and display styles
//
// Adjusted line heights and default margins

h1,.h1{
  line-height: 1.1;
}

h2,.h2{
  line-height: 1.1;
}

h3,.h3{
  line-height: 1.1;
}

h4,.h4{
  line-height: 1.1;
  margin-bottom: $spacer*2;
}

h5,.h5{
  line-height: 1.1;
  margin-bottom: $spacer/2;
}

h6,.h6{
  line-height: 1.1;
  margin-bottom: $spacer/2;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6{
  &:last-child{
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  h1,.h1{
    font-size: $h2-font-size;
    line-height: 2.5rem;
  }
  h2, .h2{
    font-size: $h3-font-size;
    line-height: 2.25rem;
  }
}

.h1,.h2,.h3,.h4,.h5,.h6,.display-1,.display-2,.display-3,.display-4{
  display: block;
}

.display-1,.display-2,.display-3,.display-4{
  margin-bottom: $spacer;
  color: $headings-color;
}

.display-1{
  line-height: 6rem;
}

.display-2{
  line-height: 6rem;
}

.display-3{
  line-height: 4.5rem;
}

.display-4{
  line-height: 4rem;
}

@include media-breakpoint-down(xs) {
  .display-1{
    font-size: $display2-size;
  }
  .display-2{
    font-size: $display3-size;
    line-height: 4.5rem;
  }
  .display-3{
    font-size: $display4-size;
    line-height: 4rem;
  }
  .display-4{
    font-size: $h1-font-size;
    line-height: 3rem;
  }
}

// Horizontal rules
//
//

hr{
  margin: $spacer*1.5 0;
}

@include media-breakpoint-down(sm) {
  hr{
    margin: $spacer 0;
  }
}

hr.short{
  width: $spacer*2;
  margin: $spacer 0;
}

// Blockquotes
//
//

.blockquote{
  text-align: center;
  .h4{
    line-height: 2.25rem;
  }
}

// Abbreviations
//
//

abbr[title]{
  -webkit-text-decoration: underline dotted;
}

// Other text styles
//
// Adjusted line heights and default margins and font weights

p{
  &:last-child{
    margin-bottom: 0;
  }
}

.text-small,h6+p,h6+p+a,h6+span,.h6+span{
  font-size: 0.875rem;
}

.lead{
  margin-bottom: $spacer;
  line-height: 1.875rem;
  display: block;
  font-weight: $font-weight-normal;
}

@include media-breakpoint-down(xs) {
  .lead{
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

small{
  line-height: 1.5;
  display: inline-block;
}

h6+small,.h6+small,h5+small,.h5+small{
  position: relative;
  bottom: .25rem;
}

dt{
  font-weight: $font-weight-bold;
}
