//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body, p, {
  font-family: 'lato', sans-serif !important;
  color: #000000 !important;
  font-size: 18px !important;
}

.navbar {
  border-top: solid 5px #3D7FC1;
  padding: 0 !important;
  border-bottom: solid 1px #e9ecef;
}

.nav-link {
  color: #677384 !important;
}

.nav-link:hover {
  color: #3D7FC1 !important;
}

.hero-banner {
  background-color: #3D7FC1 !important;
  margin: 0 !important;
  padding: 1rem !important;
}

.hero-title {
  text-align: center;
}

.expert-card-photo {
  width: 6rem !important;
  align-self: center;
  margin-bottom: -4rem;
}

.technology-card-img {
  width: 100%;
  height: 13rem;
  object-fit: cover;
}

.hide-column {
  @include media-breakpoint-down(xs){
    display: none;
  }
}


.sidebar-column {
  text-align: center;

  @include media-breakpoint-up(xl) {
    position: sticky !important;
    height: 100%;
    top: 6.575rem;
    text-align: left;
    z-index: 1000;
  }

  .list-unstyled li { margin-bottom: 0.375rem !important; }
  .list-unstyled li:last-child { margin-bottom: 0 !important; }
}

footer {
  width: 100vw;
  background-color: #3D7FC1;
  padding: 3rem;
  p, a { color: #fff; }
  a { font-weight: 500; }
}

.row-links li {
  :hover { background-color:#f7f7f7; }
  a {
    color:#000000;
  }
  .h5 { color: #3D7FC1; }
}

.feature-list {
  .card:hover { background-color: #f7f7f7; }
  h4 {color: #3D7FC1; }
}

.card-footer .list-inline-item img, td img, span img {
  max-width: 3rem;
  max-height: 2rem;
}

th {
  font-size: 16px;
}

body { background-color: #3D7FC1 !important; }

.main-wrap {
  padding-top: 1.5rem;
  padding-bottom: 5rem;
  background-color: #f8f9fa !important;
}

h5, .h5 { font-weight: 700 !important; }


.card-expert .card-footer { height: 57px; }
